import React from "react";

export const Blank = (): JSX.Element => {
  return (
    <div id='content' className='min-h-[70vh]'>
      This a blank page
    </div>
  );
};
export default Blank;
